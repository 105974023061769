ul.recording-collection {
    max-height: 23vh;
	overflow-y: scroll;
	padding-left: 10px;
	list-style-type: none; /* not sure if you need this. Hides bullet list dots */
}

#style-1::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
border-radius: 10px;
background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
width: 12px;
background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: #555;
}


table {
  table-layout: fixed;
}


.tabs .tab a{
    color:#00796b;
} /*Black color to the text*/

.tabs .tab a:hover {
    background-color:#e0f2f1;
    color:#00796b;
} /*Text color on hover*/

.tabs .tab a.active {
    background-color:#e0f2f1;
    color:#00796b;
} /*Background and text color when a tab is active*/

.tabs .indicator {
    background-color:#00796b;
} /*Color of underline*/

.loader {
    margin-left: 50%;
    margin-right: 50%;
}
